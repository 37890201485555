.left-sidebar {
    width: 280px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background:rgba(0,0,0,0.2);
    backdrop-filter: blur(10px); 
    z-index: 2;
    border-right: 3px solid #4877b4;
    border-image: linear-gradient( -35deg, rgba(255, 202, 2, 0.5), rgba(255, 202, 2,     0.5), rgba(255, 202, 2, 1),rgba(255, 202, 2, 0.5),rgba(255, 202, 2, 0.5)) 1;
    display: flex;
    flex-direction: column;
}
.logo-wrapper {
    display: flex;
    justify-content: center;
    padding: 25px 0;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.12);
    flex-direction: column;
    text-align: center;
}
.logo-wrapper img {
    width: 75px;
}
.logo-wrapper p {
    margin-top: 15px;
    font-size: 12px;
    color: #fff;
    line-height: 20px;
    font-family: 'bpgcaps';
    font-weight: bold;
    margin-bottom: 0;
    padding: 0 15px;
}
.sidebar-menu {
    padding-bottom: 10px;
    overflow-y: auto;
    max-height: 100%;
}
.sidebar-menu::-webkit-scrollbar {
    width: 0;
  }
  
  /* Track */
  .sidebar-menu::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  .sidebar-menu::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  /* Handle on hover */
  .sidebar-menu::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
.sidebar-menu ul {
    list-style: none;
    padding: 0;
}
.sidebar-menu ul li {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-left-width: 2px;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    transition: all 0.3s;
    padding-top: 20px;
}
.sidebar-menu ul li.active {
    border-left-color: #ffca00;
    background-color: #284e7a;
}
.sidebar-menu ul li.active span {
    color: #ffca00;
}
.sidebar-menu ul li:first-child {
    border-top: 1px solid #375b88;
}
.sidebar-menu ul li:not(:last-child) {
    border-bottom: 1px solid #375b88;
}
.sidebar-menu ul li .menu-title {
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    transition: all 0.3s;
    font-family: 'firago';
}
.sidebar-menu ul li .menu-sub {
    color: #fff;
    font-size: 10px;
    opacity: 0.75;
    display: inline-block;
    margin-top: 5px;
    transition: all 0.3s;
    font-family: 'firago';
}
.sidebar-menu ul li:hover {
    border-left-color: #ffca00;
    background-color: #284e7a;
}
.sidebar-menu ul li:hover span {
    color: #ffca00;
}
@media (max-width: 1024px) {
    .sidebar-menu {
        max-height: 100%;
        padding-bottom: 0;
    }
    .left-sidebar {
        z-index: 5;
        transform: translateX(-100%);
        transition: all 0.3s;
        visibility: hidden;
        top: 52px;
        backdrop-filter: blur(25px);
        border: none;
        height: calc(100% - 52px);
    }
    .left-sidebar .logo-wrapper {
        display: none;
    }
    .left-sidebar.active{
        transform: translateX(0);
        transition: all 0.3s;
        visibility: visible;
    }
  
}
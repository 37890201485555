@font-face {
    font-family: 'bpgcaps';
    src: url('../fonts/bpgcaps.ttf');
}
@font-face {
    font-family: 'firago';
    src: url('../fonts/FiraGO.otf');
}
@font-face {
    font-family: 'bpgnino';
    src: url('../fonts/bpgnino.ttf');
}
@font-face {
    font-family: 'robotoReg';
    src: url('../fonts/RobotoRegular.ttf');
}
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}

body {
    min-height: 768px;
    position: relative; 
    height: 100vh;
    background: rgb(61,116,175);
    background: linear-gradient(180deg,#3d74af,#3869a7 25%,#193d73);
}
body::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/bg.png'); 
    background-size: cover;
    background-repeat: repeat;
    background-position: -60px center;
    background-color: rgba(255,255,255,0.02);
}
.content-wrapper {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
}
.content-list {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    padding-left: 280px;
}
.horisontal-line {
    position: absolute;
    left: 280px;
    top: 50%;
    transform: translateY(-50%);
    height: 5px; 
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.24);
    width: calc(100% - 280px);
    background: #4877b4;
}
.scroll-horizontal {
    height: 100vh !important;
    min-height: 768px;
}
.circle {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 5px solid #4877B4;
    background: #294E87;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    animation-duration: 1.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    box-shadow: -1px 1px 2px rgba(0,0,0,0.24);
  }
  
  .circle:hover {
    animation-name: scale;
    transition: all 0.3s;
  }
  
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
.circle h4 {
    font-size: 25px;
    font-family: 'firago';
    font-weight: bold;
    color: #fff;
    margin-bottom: 0;
}
.circle p {
    font-size: 10px;
    opacity: 0.7;
    font-family: 'firago';
    color: #fff;
}
.column-item {
    height: 100%;
    min-width: 120px;
    margin: 0 35px;
    position: relative;
     display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.column-item:first-child {
    margin-left: 340px;
}
.event-item {
    width: 40px;
    height: 40px;
    max-height: 40px;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid #ffca00;
    opacity: 0;
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
}
.top-events .event-item:last-child {
    margin-bottom: 0;
}
.bottom-events .event-item:last-child {
    margin-bottom: 0;
}
.top-events {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    height: 200px;
    margin-bottom: 20px;
}
.bottom-events {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    height: 200px;
    margin-top: 20px;
}
.bottom-events .event-tooltip {
    top: -100px !important;
}
.headline {
    position: absolute;
    top: 48px;
    left: 50%;
    z-index: 4;
    transform: translateX(-50%);
    margin-left: 140px;
    text-align: center;
    white-space: nowrap;
}
.headline .top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.headline .top img {
    width: 55px;
}
.headline h1 {
    font-size: 22px;
    color: #fff;
    font-family: 'bpgnino';
    font-weight: bold;
    margin: 8px 10px 0;
}
 
.multiple .event-item {
    width: 28px;
    height: 28px;
    margin-bottom: 8px;
    border-width: 2px;
    cursor: pointer;
    
}
.event-item::before {
    content: '';
    background: #FFCA02;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    opacity: 0;
    transition: all 0.3s;
}
.event-item .event-date {
    z-index: 1;
    position: relative;
    font-size: 10px;
    font-family: 'firago';
    font-weight: bold;
    color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    cursor: pointer;
}
.event-item .event-date span {
    display: inline-block;
    margin-top: 7px;
    font-size: 15px;
    margin-bottom: 1px;
}
.event-item:hover .event-date {
    opacity: 1;
    transition: all 0.3s;
}
.event-item:hover::before {
    opacity: 0.7;
    transition: all 0.3s;
}
.multiple .event-date {
    font-size: 6px;
}
.multiple .event-date span {
    font-size: 15px;
}
.multiple .event-date p {
    display: none !important;
}
.event-item .event-tooltip {
     position: absolute;
     width: 400px;
     display: flex;
     overflow: hidden;
     visibility: hidden;
     opacity: 0;
     transform: scale(0);
     transition: all 0.3s;
     padding-left: 55px;
     top: -25px;
     z-index: 1;
     transform-origin: top left;
}
.event-item:hover .event-tooltip {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
}
/* .event-item .event-tooltip::before {
    content: '';
    border-top: 10px solid transparent;
    border-right: 10px solid #fff;
    border-bottom: 10px solid transparent;
} */
.event-item .event-tooltip .event-img {
    min-width: 150px;
    height: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}
.event-item .event-tooltip .event-data {
    min-height: 150px;
    text-align: center;
    background: #fff;
    border-radius: 12px;
    position: unset;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.event-item .event-tooltip .event-data img {
    max-width: 100%;
    border-radius: 8px;
}
.event-item .event-date p {
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: 82%;
    line-height: normal;
    overflow: hidden;
    text-align: center;
}
.event-item .event-tooltip h3 {
    font-size: 20px;
    color: #000000;
    font-family: 'firago';
    font-weight: bold;
}
.event-item .event-tooltip p {
    font-size: 12px;
    font-family: 'firago';
    line-height: 18px;
    margin-bottom: 0;
    text-align: left;
    padding: 15px;
    min-height: 50px;
}
.onLeft .event-item .event-tooltip {
    left: unset;
    right: 0;
    padding-right: 55px;
    transform-origin: right top;
}
.no-transform > div{
    transform: unset !important;
}
.custom-modal-wrapper {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(36 91 168 / 80%);
    z-index: 999;
    width: 100%;
}
.custom-modal-wrapper.active {
  
    opacity: 1;
    visibility: visible;
}
.custom-modal-content {
     background-color: #fff;
     margin: 50px auto;
     border-radius: 15px;
     max-width: 70vw;
     display: flex;
     position: relative;
     align-items: flex-start;
     overflow: hidden;
     opacity: 0;
     visibility: hidden;
     transform: translateY(100px);
     transition: all 0.3s;
}
.custom-modal-wrapper.active  .custom-modal-content {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: all 0.3s;
}
.cover {
    width: 300px;
    overflow-y: auto;
    min-height: 400px;
    position: absolute;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.cover::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 40%);
}
.description {
    padding: 0 25px;
    max-height: 80vh;
    overflow-y: auto;
    min-height: 400px;
    width: 100%;
    padding-bottom: 30px;
}
.custom-modal-right {
    margin-left: 300px;
    padding-left: 15px;
    width: calc(100% - 300px);
}
.description * {
    font-family: 'firago' !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #333 !important;
    word-break: break-word;
}
.description ol, .description ul {
    width: 100% !important;
    margin: 15px 0 !important;
    padding-left: 0 !important;
}
.description p ol, .description p ul {
    padding-left: 15px !important;
}
.description ol li, .description ul li {
    margin: 10px 0 !important;
    white-space: unset !important;
}
.description strong, .description b {
    font-weight: bold !important;
}
.custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px 15px;
    width: 100%;
}
.custom-modal-header h2 {
    font-size: 20px !important;
    font-family: 'bpgnino' !important;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 0;
}
.custom-modal-header .close-modal {
    cursor: pointer;
    cursor: pointer;
    margin-top: -7px;
}
.custom-modal-header .close-modal svg {
    width: 15px;
    height: 15px;
}
.modal-footer {
    justify-content: space-between !important;
}
.modal-footer p {
    color: #edb300;
    font-family: 'firago';
    font-weight: bold;
}
.test-mode {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    width: calc(100% - 280px);
    background: #1e3f72;
    padding: 8px;
    color: #fff;
    font-size: 14px;
    font-family: 'firago';
}
.lang-switcher {
    position: absolute;
    top: 50px;
    z-index: 2;
    right: 20px;
    background: rgb(255 255 255 / 20%);
    border-radius: 8px;
    overflow: hidden;
}
.lang-switcher button {
    border: none;
    background: transparent;
    font-family: 'firago';
    font-size: 14px;
    height: 100%;
    padding: 5px 10px;
}
.lang-switcher button.active {
    background: rgb(30 64 114);
    color: #fff;
}
@media (max-width: 1024px) {
    .lang-switcher {
        top: 10px;
        z-index: 88;
    }
    body::before {
        background-image: url('../images/bg-mob.png');
        background-position: center;
    }
    .headline, .test-mode {
        display: none;
    }
    .horisontal-line {
        left: 50%;
        transform: translate(-50%, 0);
        width: 10px;
        height: 100%;
        top: 0;
        width: 6px;
    }
    .scroll-horizontal  {
        width: 100% !important;
        overflow: unset !important;
        position: unset !important;
        height: auto !important;
        min-height: unset !important;
    }
    .scroll-horizontal > div {
        transform: unset !important;
        position: unset !important;
        display: flex !important;
        flex-direction: column;
        overflow-y: auto;
        height: calc(100vh - 130px) !important;
        padding-top: 130px;
    }
    .content-wrapper .column-item {
        margin-left: 0;
        margin-right: 0;
        flex-direction: row;
        margin-bottom: 60px;
    }
    .content-wrapper .column-item:last-child {
        margin-bottom: 140px;
    }
    .circle {
        position: unset !important;
        transform: unset !important;
        min-width: 80px;
        width: 80px;
        height: 80px;
        opacity: 1 !important;
    }
    .circle h4 {
        font-size: 20px;
    }
    .circle p {
        font-size: 9px;
    }
    .bottom-events {
        height: auto;
        margin-top: 0;
        padding-left: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .top-events {
        height: auto;
        margin-bottom: 0;
        align-items: flex-end;
        justify-content: center;
        padding-right: 30px;
    }
    .event-item {
        visibility: visible;
        opacity: 1 !important;
        width: 40px;
        min-height: 40px;
        transform: unset !important;
    }
    .event-tooltip {
        display: none !important;
    }
    .mobile-modal {
        position: fixed;
        background: #fff;
        bottom: 0;
        max-height: 80vh;
        display: flex;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        overflow: hidden;
        flex-direction: column;
        min-height: 500px;
        transform: translateY(100%);
        transition: all 0.3s;
        opacity: 0;
    }
    .mobile-modal.active {
        transform: translateY(0);
        opacity: 1;
        transition: all 0.3s;
    }
    .mobile-modal .header-item {
        padding: 25px;
        display: flex;
        justify-content: space-between;
    }
    
    .mobile-modal .header-item h4 {
        color: #333333;
        font-size: 18px;
        font-family: 'firago';
        font-weight: bold;
    }
    .mobile-modal .header-item .close-modal {
        cursor: pointer;
    }
    .mobile-modal .header-item .close-modal svg {
        width: 15px;
        height: 15px;
    }
    .mobile-modal .body-item {
        padding: 0 25px 25px;
    }
    .mobile-modal .body-item .img {
        background-position: 50%;
        background-size: 128%;
        border-radius: 15px;
        height: auto;
        margin-bottom: 15px;
        width: 100%;
        background-repeat: no-repeat;
        padding-top: 36%;
        overflow: hidden;
    }
    .mobile-modal .body-item p {
        color: #333333;
        font-size: 14px;
        font-family: 'firago';
        line-height: 24px;
    }
    .mobile-header {
        background: #2a4f7f;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: center;
    }
    .mobile-header img {
        width: 72px;
        height: 72px;
        padding: 10px;
        background: #2a4f7f;
        border-radius: 100%;
        margin-bottom: -20px;
    }
    .mobile-header .burger-btn {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .custom-modal-wrapper .custom-modal-content {
        flex-direction: column;
        max-width: 100%;
        top: 0;
        border-radius: 0;
        margin: 0;
        height: 100%;
        display: flex;
    }
    .custom-modal-wrapper .cover {
        position: relative;
        width: 100%;
        min-height: 160px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .custom-modal-wrapper .cover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0 43 87 / 50%);
    }
    .custom-modal-wrapper .cover h2 {
        font-size: 18px !important;
        font-family: 'bpgnino' !important;
        font-weight: bold;
        color: #fff;
        z-index: 1;
        text-align: center;
        line-height: 25px;
        padding: 0 15px;
    }
    .custom-modal-wrapper .cover .close-modal {
        z-index: 1;
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .custom-modal-wrapper .cover .close-modal svg {
        width: 15px;
        height: 15px;
        fill: #fff;
        opacity: 0.8;
    }
    
    .custom-modal-wrapper .custom-modal-right {
        margin-left: 0;
        padding-left: 0;
        width: 100%;
        display: flex;
    }
    .custom-modal-wrapper .custom-modal-header {
        display: none;
    }
    .description {
        height: calc(100% - 120px);
        overflow-y: auto;
        min-height: unset;
        padding-bottom: 15px;
        padding-top: 15px;
        min-height: 100px;
    }
}

@media (min-width: 1025px) {
    .mobile-modal {
        display: none !important;
    }
    .cover h2 {
        display: none;
    }
    .cover .close-modal {
        display: none
    }
    .mobile-header {
        display: none;
    }
}
.description pre {
    border-top: 1px solid #ccc;
    padding-top: 5px;
    margin-top: 15px;
}
.description pre * {
    font-size: 12px !important;
}
.description sup {
    font-size: 10px !important;
    position: relative;
    top: -5px;
    left: 2px;
}

.dashboard-wrapper {
    width: 100%;
    height: 100vh;
    flex: 1;
    background-color: #fff;
    z-index: 1;
    position: relative;
}
.header-wrapper {
    height: 60px;
    width: 100%;
    background: #1e3f72;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
}
.header-wrapper h2 {
    color: #fff;
    font-family: 'firago';
    margin: 0;
    font-size: 20px;
}
.header-wrapper button {
    border: none;
    background: #fff;
    padding: 8px 15px;
    border-radius: 8px;
    font-family: 'firago';
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
}
.header-wrapper button:hover {
    opacity: 0.8;
}
.signIn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}
.signIn-card {
    width: 400px;
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    z-index: 1;
    position: relative;
}
.signIn-card h3 {
    text-align: center;
    font-size: 20px;
    font-family: 'firago';
}
.signIn-form {
    width: 100%;
    margin-top: 35px;
}
.signIn-form .form-item {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
}
.signIn-form .form-item input {
    height: 50px;
    width: 100%;
    font-family: 'firago';
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 8px;
}
.signIn-form .form-item button {
    width: 100%;
    border: none;
    height: 50px;
    font-family: 'firago';
    background: #2d568f;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}
.signIn-form .form-item button:hover {
    opacity: 0.8;
}
.error {
    margin-bottom: 15px;
    color: red;
    font-family: 'firago';
}
.dashboard-content {
    max-width: 1200px;
    margin: 40px auto 0;
}
.nav-item {
    padding: 0;
}
.nav-item button {
    font-family: 'bpgcaps';
    font-weight: bold;
    padding: 25px 25px 20px;
    font-size: 16px;
}
.nav-item button[aria-selected="false"] {
    color: #d8ab00;
}
.tab-content {
    padding: 25px 0;
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 25px;
}
.tab-content table img {
    width: 50px;
    border-radius: 5px;
}
.tab-content table td {
    vertical-align: middle;
    font-family: 'firago';
    font-size: 14px;
    border: 1px solid #eeeeee;
    padding: 15px;
}
.tab-content table td p * {
    font-size: 14px !important;
    text-align: left !important;
    color: #000 !important;
    font-family: 'firago' !important;
    list-style-type: none !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
    font-weight: unset !important;
}
.tab-content table .action-buttons {
    display: flex;
    align-items: center;
}
.tab-content table .action-buttons button {
    border: none;
    padding: 8px 15px;
    font-size: 14px;
    margin: 0 5px;
    border-radius: 5px;
}
.tab-content table .action-buttons button.edit {
    background: #ffce00;
}
.tab-content table .action-buttons button.delete {
    background: red;
    color: #fff;
}
.tab-top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
}
.tab-top .add {
    background: #1e3f72;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    font-family: 'firago';
    border-radius: 5px;
}
button:hover {
    opacity: 0.8;
}
.modal.show .custom-modal-content {
    opacity: 1;
    visibility: visible;
}
.custom-modal-footer {
    width: 100%;
}
.custom-modal-footer button {
    background: #1e3f72;
    font-family: 'firago';
    font-size: 14px;
    padding: 10px 25px;
}
.image-preview {
    display: flex;
    align-items: center;
}
.dropzone[role="presentation"] {
    width: 120px;
    height: 120px;
    position: relative;
    background: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-preview:hover {
    opacity: 0.8;
}
.image-preview p {
    margin: 0;
    font-family: 'firago';
    font-size: 14px;
}
.image-preview input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.image-preview img {
    height: 100%;
}
.custom-modal-body {
    width: 100%;
}
.form-label {
    font-family: 'firago';
    font-weight: bold;
    font-size: 14px;
}
.custom-modal-body input {
    font-family: 'firago';
    font-size: 14px;
}
.thumbsContainer {
    margin-left: 15px;
}
.thumbsContainer img {
    height: 120px;
    width: 100%;
}
textarea {
    font-family: 'firago' !important;
    font-size: 14px !important;
}
[data-lang="true"] * {
    font-family: 'robotoReg' !important
}
[data-lang="true"] .logo-wrapper p {
    font-size: 15px;
}
[data-lang="true"] .headline h1 {
    margin-top: 0;
}
.skeleton {
    background: linear-gradient(110deg, rgb(236 236 236 / 6%) 8%, rgb(245 245 245 / 7%) 18%, #2647742b 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    overflow: hidden;
    margin-bottom: 15px;
    height: 50px;
}
@keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
 